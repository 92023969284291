<template>
  <el-card>
    <!-- <div class="card-header"> -->
      <el-row>
        <el-col :span="1">
          <el-button class="button" type="text" @click="showAddUserDialog"
            >添加账号+</el-button
          >
        </el-col>
        <el-col :span="22">
            <el-radio-group v-model="radio1" @change="getList">
              <el-radio-button :label="userManager"></el-radio-button>
              <el-radio-button :label="newUserAudit"></el-radio-button>
            </el-radio-group>
        </el-col>
      </el-row>
    <!-- </div> -->
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        max-height="750"
        :key="itemKey"
        v-loading="loading"
      >
        <el-table-column prop="account" label="用户名" width="300" />
        <el-table-column prop="role" label="权限" width="120">
          <template v-slot="{ row }">
            <el-label>{{ showRole(row.role) }}</el-label>
          </template>
        </el-table-column>
        <el-table-column prop="department" label="部门" width="300" />
        <el-table-column prop="createdAt" label="加入日期" width="250" />
        <el-table-column prop="lastLogin" label="最后登陆日期" width="250" />
        <el-table-column>
          <template v-slot="{ row }">
            <el-button @click="updateClick(row)" v-if="radio1 == userManager"
              >修改</el-button
            >
            <el-button
              @click="auditUser(row, auditSuccess)"
              v-if="radio1 == newUserAudit"
              >通过</el-button
            >
            <el-button
              @click="auditUser(row, auditRefuse)"
              v-if="radio1 == newUserAudit"
              >拒绝</el-button
            >
          </template>
        </el-table-column>
        <!-- <el-table-column prop="pwd" label="密码" width="120" sortable="custom" /> -->
      </el-table>
    </div>
  </el-card>
  <el-dialog v-model="operateUserDialogVisible" title="修改用户">
    <el-form
      :model="userInfo"
      ref="operateUserForm"
      :rules="rules"
      label-width="60"
      v-loading="loading"
    >
      <el-row justify="center">
        <el-col :span="10">
          <el-form-item
            label="ID"
            prop="id"
            v-show="!addUserShow"
            style="padding-left: 24px; width: 280px"
          >
            <el-input
              type="text"
              v-model.number="userInfo.id"
              disabled
              class="form-input"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row justify="center">
        <el-col :span="10">
          <el-form-item label="账号" prop="account" style="width: 306px">
            <el-input
              type="text"
              v-model="userInfo.account"
              class="form-input"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row justify="center">
        <el-col :span="10">
          <el-form-item label="权限" prop="role">
            <el-select
              v-model.number="userInfo.role"
              class="form-input"
              style="width: 256px"
            >
              <el-option label="管理员" :value="1"></el-option>
              <el-option label="普通用户" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row justify="center">
        <el-col :span="10">
          <el-form-item
            label="密码"
            prop="password"
            label-width="65"
            style="padding-left: 10px; width: 300px"
          >
            <el-input
              type="password"
              v-model="userInfo.password"
              class="form-input"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row justify="center">
        <el-col :span="10" :offset="6">
          <el-form-item :label-width="0">
            <el-button @click="addUser" v-show="addUserShow">添加</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row justify="center">
        <el-col :span="3">
          <el-form-item :label-width="0">
            <el-button @click="addUser" v-show="!addUserShow">更新</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item :label-width="0">
            <el-button @click="delUser" v-show="!addUserShow">删除</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  getUserList,
  addUser as addUserApi,
  delUser as delUserApi,
  userAudit as userAuditApi,
  getUserAuditList,
} from "../api/user";
import { messageError } from "../utils/message";

let pwdRule = { required: true, message: "请输入密码", trigger: "blur" };
const userManager = "用户管理";
const newUserAudit = "新用户审核";
const auditSuccess = 1;
const auditRefuse = 2;
export default {
  name: "user",
  data() {
    return {
      auditSuccess,
      auditRefuse,
      userManager,
      newUserAudit,
      radio1: userManager,
      loading: false,
      addUserShow: true,
      itemKey: "",
      operateUserDialogVisible: false,
      userInfo: {
        id: undefined,
        account: "",
        password: "",
        role: undefined,
      },
      rules: {
        account: [
          {
            required: true,
            message: "用户账号必填",
            trigger: "blur",
          },
          {
            max: 30,
            message: "长度不能超过30个字符",
            trigger: "blur",
          },
        ],
        role: { required: true, message: "请选择用户角色", trigger: "blur" },
        password: pwdRule,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      if (this.radio1 == userManager) {
        // 获取用户列表
        getUserList()
          .then((resp) => {
            this.tableData = resp.data.data.records;
            this.itemKey = Math.random();
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      } else {
        // 获取用户审核列表
        getUserAuditList()
          .then((resp) => {
            this.tableData = resp.data.data.records;
            this.itemKey = Math.random();
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    // pushCurrentPage(){
    //   this.$router.push({
    //     name:"userList",
    //     query: {radio: this.radio1}
    //   })
    // },
    showAddUserDialog() {
      this.rules.password.required = true;
      this.userInfo = {
        id: undefined,
        account: "",
        password: "",
        role: undefined,
      };
      this.addUserShow = true;
      this.operateUserDialogVisible = true;
    },
    addUser() {
      this.$refs.operateUserForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          addUserApi(this.userInfo)
            .then(() => {
              this.loading = false;
              this.operateUserDialogVisible = false;
              this.getList();
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        }
      });
    },
    delUser() {
      this.loading = true;
      delUserApi({ id: this.userInfo.id })
        .then(() => {
          this.loading = false;
          this.operateUserDialogVisible = false;
          this.getList();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      this.getList();
      this.operateUserDialogVisible = false;
    },
    updateClick(row) {
      (this.rules.password.required = false), (this.addUserShow = false);
      this.userInfo = {
        id: row.id,
        account: row.account,
        password: "",
        role: row.role,
      };
      this.operateUserDialogVisible = true;
    },
    showRole(role) {
      const roleMap = {
        1: "管理员",
        2: "普通用户",
      };
      const roleStr = roleMap[role];
      if (roleStr) {
        return roleStr;
      }
      return role;
    },
    auditUser(row, ret) {
      this.loading = true;
      userAuditApi({ id: row.id, ret: ret })
        .then(() => {
          this.getList();
        })
        .catch((err) => {
          console.log(err);
          messageError("操作失败！！！");
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-input {
  width: 500;
}
.el-card {
  margin: 5px;
}
</style>